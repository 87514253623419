const React = require('react');
const ReactDOM = require('react-dom/client');

// wrapPageElement using CommonJS syntax
exports.wrapPageElement = ({ element, props }) => {
  const Layout = element.type.Layout ?? React.Fragment;
  return React.createElement(Layout, props, element);
};

// replaceHydrateFunction using CommonJS syntax
exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
